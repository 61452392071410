import React from 'react';
import "./About.css";
import Card from 'react-bootstrap/Card';
import safari from './programming.jpg';

const AboutPage = () => {
  return (
    <div className="about-page">
      <h1>About Me</h1>
      <Card className="card h-100">
      <Card.Img variant="top" src={safari} />
        <Card.Body>
          <Card.Text className="about-text">
            This is Melvin safari's Portfolio where you will get to view Jobs and Projects that is tech-based innovated,
            I also offer services to companys and firms of different organizations at affordable prices.
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};
export default AboutPage;

