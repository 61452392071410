import React from "react";
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Portfolio from "./components/Portfolio";
import "./App.css";
//import Footer from "./components/Footer";
function App() {
  return (
    <Router>
      <div>
        <Navbar bg="light" expand="lg" className="justify-content-center mb-4 sticky-top">
          <Navbar.Brand href="/" style={{ color: '#ffa07a', fontSize: '2rem' }}>Melvin Safari</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto justify-content-center">
              <NavLink to="/home" className="nav-link mr-3">
                Home
              </NavLink>
              <NavLink to="/about" className="nav-link mr-3">
                About
              </NavLink>
              <NavLink to="/services" className="nav-link mr-3">
                Services
              </NavLink>
              <NavLink to="/portfolio" className="nav-link mr-3">
                Portfolio
              </NavLink>
              <NavLink to="/contact" className="nav-link">
                Contact
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </div>
   
    </Router>
  );
}

export default App;












