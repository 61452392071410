import React from 'react';
import './Portfolio.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const Portfolio = () => {
  const workDone = [
    { id: 1, title: 'Project 1', description: 'A responsive Dimba application made with React technology ', link: 'https://group-three-phase-2-project.vercel.app/' },
    { id: 2, title: 'Project 2', description: 'A Money saving App; Uwezo Sacco implemented with react and ruby on rails technologies.', link: 'https://uwezosacco.netlify.app/' },
    { id: 3, title: 'Project 3', description: 'A Jokes app made with react and ruby on rails technologies for generating random jokes around the world, Final completion too soon', link: 'https://686e5507.jokes-frontend.pages.dev/' },

  ];

  return (
    <div className="portfolio-container">
      <h1>Work Done</h1>
      <div className="card-deck">
        {workDone.map((work) => (
          <Card key={work.id} className="mb-4 bg-dark">
            <Card.Body className="my-card-body">
              <Card.Title>{work.title}</Card.Title>
              <Card.Text>{work.description}</Card.Text>
              <Button variant="primary" href={work.link}>View Work</Button>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;

