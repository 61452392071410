import React from 'react';
import "./Services.css";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import ruby from './rails.png';
import design from './design.png';
import development from './learn.png';

const Services = () => {
  return (
    <div className="services">
      <h1>Services</h1>
      <Row className="d-flex flex-column">
        <Card className="card h-100">
          <Card.Img variant="top" src={ruby} />
          <Card.Body>
            <Card.Title>API Development with Rails</Card.Title>
            <Card.Text classname="card-text">
              I provide experties in API development services using Ruby on Rails.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="card h-100">
          <Card.Img variant="top" src={design} />
          <Card.Body>
            <Card.Title>UI/UX Web Development</Card.Title>
            <Card.Text classname="card-text">  
                     I create beautiful and responsive user friendly web pages and landing pages as per your organizations or business needs  <b>(UI/UX) </b>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className="card h-100">
          <Card.Img variant="top" src={development} />
          <Card.Body>
            <Card.Title>Software Engineering SQL</Card.Title>
            <Card.Text classname="card-text">
              I offer software engineering services; <span>skilled in SQL to develop efficient and effective software solutions to meet your organizational and business needs with developing API for use with React.</span>
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
    </div>
  )
}

export default Services;



