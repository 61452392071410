import React from "react";
import { Card } from "react-bootstrap";
import development from "./melvin.jpeg";
//import "./Card.css";

const CardComponent = () => {
  return (
    <Card className="my-card text-center">
      <div className="img-container">
        <Card.Img variant="top" src={development} className="rounded-circle" />
      </div>
      <Card.Body>
        <Card.Title>Welcome To  My Portfolio</Card.Title>
        <Card.Text>
        My name is Melvin Safari Kukwan. I am a certified software developer with skills in <span>React, Bootstrap, JavaScript, Ruby
          on Rails, Postman, SQL, Postman, Figma and Jira. Find about me through the <b>MENU</b> at the top 
        </span>

        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CardComponent;



