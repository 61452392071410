import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./Contact.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Card from 'react-bootstrap/Card';

const ContactPage = () => {
  return (
    <div className='contact-container'>
      <h1>Contact Me</h1>
      <p>Follow me at my social media platforms below.</p>
      <div className='social-icons'>
        <a href='https://www.facebook.com/profile.php?id=100087225795201' className='btn btn-primary'>
          <FontAwesomeIcon icon={faFacebook} /> Facebook
        </a>
       
        <a href='https://instagram.com/melvin_safari?igshid=ZDdkNTZiNTM=' className='btn btn-danger'>
          <FontAwesomeIcon icon={faInstagram} /> Instagram
        </a>
      </div>
      <div className='contact-info'>
        <Card className='card'>
          <Card.Body>
            <Card.Title>Address</Card.Title>
            <Card.Text>Mwalimu Street.<br/>Baragoi, Kenya</Card.Text>
          </Card.Body>
        </Card>
        <Card className='card'>
          <Card.Body>
            <Card.Title>Phone</Card.Title>
            <Card.Text>+254701368375</Card.Text>
          </Card.Body>
        </Card>
        <Card className='card'>
          <Card.Body>
            <Card.Title>Email</Card.Title>
            <Card.Text>kukwanm571@gmail.com</Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ContactPage;


